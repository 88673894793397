import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { urlWithSearchParamsHandler, srcSetProps, sanityFileUrl, sanityImageUrl, clickTrack } from '../../../utils/helpers'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  if (!howItWorks || howItWorks.isHide) return null

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <div className={styles.left}>
            <h2 className={styles.title}>{howItWorks.title}</h2>
            {howItWorks.buttonText && (
              <a href={howItWorks.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.btn}>
                {howItWorks.buttonText}
              </a>
            )}     
          </div>
          <div className={styles.right}>
            <p className={styles.info}>{howItWorks.info}</p>
            <p className={styles.desc}>{howItWorks.description}</p>
          </div>
        </div>
        {howItWorks.buttonText && (
          <a href={howItWorks.buttonUrl} onClick={urlWithSearchParamsHandler} className={cx(styles.btn, styles.btnMobile)}>
            {howItWorks.buttonText}
          </a>
        )}
        <p className={cx(styles.title, styles.titleMobile)}>{howItWorks.title}</p>
        <div className={styles.steps}>
          {howItWorks.items &&
            howItWorks.items.map(item => (
              <div className={styles.step} key={item._key}>
                <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                <p className={styles.stepTitle}>{item.title}</p>
                <p className={styles.stepDesc}>{item.description}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
